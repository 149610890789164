import React from "react"
import styled from "styled-components"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { HomeHeader, BannerCenter } from "../utils"
import smImg1 from "../images/gallery/azalea-miso-salmon.jpg"
import lgImg1 from "../images/gallery/azalea-dinner-1.jpg"
import { colors } from "../utils/styles"
import DishShowcase from "../components/HomePageComponents/DishShowcase"

const ContentWrapper = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  position: relative;
  height: 70dvh;
  top: 5dvh;

  @media (min-width: 992px) {
    height: 60vh;
    top: 10vh;
  }
`

const InfoWrapper = styled.article`
  display: grid;
  gap: 0;
  grid-template: 1fr 1fr 1fr / auto;

  @media (min-width: 768px) {
    grid-template: 1fr auto / 1fr 1fr;
  }
`

const InfoBox = styled.div`
  padding: 0.15rem 1rem;
  color: ${colors.mainWhite};
  font-weight: 600;
  text-transform: uppercase;
  text-align: center;
  border-color: ${colors.secondaryColor};
  border-style: solid;
  border-width: 2px;

  &:first-child {
    border-bottom-width: 0;
  }

  &:last-child {
    border-width: 0;
    padding-top: 0.5rem;
  }

  @media (min-width: 768px) {
    padding: 0.25rem 1.25rem;

    &:first-child {
      border-bottom-width: 2px;
      border-right-width: 0;
    }

    &:last-child {
      grid-column: 1/2;
      translate: 50% 0;
    }
  }
`

const IndexPage = ({ data }) => (
  <Layout>
    <Seo
      title="Home"
      keywords={[`Restaurant and bar in Kenilworth, Asheville, NC`]}
    />
    <HomeHeader smImg={smImg1} lgImg={lgImg1}>
      <ContentWrapper>
        <BannerCenter
          title="Neighborhood Bar & Kitchen"
          titleProps={{
            style: {
              fontSize: `clamp(1.5rem, 15vw, 3.8rem)`,
            },
          }}
          subtitle="Southern Fusion Fare, Craft Cocktails, & Dessert"
        ></BannerCenter>
        <InfoWrapper>
          <InfoBox>Open Wed-Sat 3pm to 9pm</InfoBox>
          <InfoBox>Open Sat-Sun 8am to 2pm</InfoBox>
          <InfoBox>5 Caledonia Rd, Asheville, NC</InfoBox>
        </InfoWrapper>
      </ContentWrapper>
    </HomeHeader>
    <DishShowcase />
  </Layout>
)

export default IndexPage
